import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import { Link } from "gatsby"

import { Prices } from "components/Prices"
import { Map } from "components/Map"
import { Access } from "components/Access"
import { Footer } from "components/Footer"
import { Links } from "components/Links"
import { PageLayout } from "layouts/PageLayout"

import * as styles from "./styles.module.scss"

const MainSection = ({ fullpageApi }) => (
  <section>
    <div className={styles.MainSection_Logo}>
      <img
        src={"/images/top_logo.png"}
        className={styles.MainSection_LogoImg}
        alt="札幌市清田区住宅街の一角にある美容室baw"
      />
    </div>
    <div className={styles.MainSection_ToAboutContainer}>
      <Link to="concept" className={styles.MainSection_ConceptButton}>
        About Baw >>
      </Link>
    </div>

    <div className={styles.MainSection_SNSBottomContainer}>
      <a href="https://www.instagram.com/baw__sapporo/">
        <img
          src={"/images/instagram256_bk.png"}
          className={styles.MainSection_SNSIcon}
          alt="美容室のinstagramへ"
        />
      </a>
    </div>

    <div className={styles.MainSection_ReservationBottomContainer}>
      <a href="http://b.hpr.jp/hp/H000699700">WEB予約</a>
    </div>
  </section>
)

const PriceSection = () => (
  <section className={styles.PriceSection_Container}>
    <PageLayout>
      <PageLayout.Title>Price</PageLayout.Title>
      <Prices />
      <div className={styles.PriceSection_Description}>
        <span>※すべて税込み価格</span>
      </div>
    </PageLayout>
  </section>
)

const AccessSection = () => (
  <section className={styles.AccessSection_Container}>
    <div className={styles.AccessSection_Box}>
      <div className={styles.AccessSection_Map}>
        <Map />
      </div>
      <Access />
    </div>
  </section>
)

export default function Home() {
  return (
    <>
      <ReactFullpage
        //fullpage options
        licenseKey={"240F6328-47E64C97-82A0A364-AE0D2783"}
        scrollingSpeed={700} /* Options here */
        sectionSelector="section"
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <MainSection fullpageApi={fullpageApi} />
              <PriceSection />
              <AccessSection />
              <section className="fp-auto-height">
                <Links />
                <Footer />
              </section>
            </ReactFullpage.Wrapper>
          )
        }}
      />
    </>
  )
}
