import React from "react"
import GoogleMapReact from "google-map-react"
import { Marker } from "./Marker"

const defaultProps = {
  center: {
    lat: 42.9880901,
    lng: 141.456455,
  },
  zoom: 16,
}
export const Map = ({
  center = defaultProps.center,
  zoom = defaultProps.zoom,
}) => {
  const mapStyles = require("./style.json")

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyDhw5QvSGcs55o9xgMGSGalO5fe0z_g5Zw" }}
      defaultCenter={center}
      defaultZoom={zoom}
      options={{
        styles: mapStyles,
        disableDefaultUI: true,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: false,
      }}
    >
      <Marker lat={42.9880901} lng={141.456455} text="Baw | 美容室" />
    </GoogleMapReact>
  )
}
