import React from "react"
import * as styles from "./styles.module.scss"

export const Prices = ({ children }) => (
  <div className={styles.Container}>
    <div className={styles.Box}>
      <ul className={styles.PriceList}>
        <h3 className={styles.Title}>cut</h3>
        <li>
          <strong>一般</strong>¥5,500
        </li>
        <li>
          <strong>大学生</strong>¥4,950
        </li>
        <li>
          <strong>中高生</strong>¥4,400
        </li>
        <li>
          <strong>キッズ</strong>¥3,850
        </li>
      </ul>
    </div>
    <div className={styles.Box}>
      <ul className={styles.PriceList}>
        <h3 className={styles.Title}>color</h3>
        <li>
          <strong>カラー</strong>¥7,150&nbsp;〜
        </li>
        <li>
          <strong>リタッチ</strong>¥6,050&nbsp;〜
        </li>
        <li>
          <strong>ダブルカラー</strong>¥15,400&nbsp;〜
        </li>
      </ul>
    </div>
    <div className={styles.Box}>
      <ul className={styles.PriceList}>
        <h3 className={styles.Title}>perm</h3>
        <li>
          <strong>パーマ</strong>¥7,700&nbsp;〜
        </li>
        <li>
          <strong>縮毛矯正</strong>¥16,500&nbsp;〜
        </li>
      </ul>
    </div>
    <div className={styles.Box}>
      <ul className={styles.PriceList}>
        <h3 className={styles.Title}>treatment</h3>
        <li>
          <strong>REZOシステム</strong>¥1,100&nbsp;〜
        </li>
        <li>
          <strong>FLOWDIA</strong>¥2,200&nbsp;〜
        </li>
        <li>
          <strong>髪質改善</strong>¥8,800
        </li>
      </ul>
    </div>
    <div className={styles.Box}>
      <ul className={styles.PriceList}>
        <h3 className={styles.Title}>head spa</h3>
        <li>
          <strong>10min</strong>¥1,650
        </li>
        <li>
          <strong>30min</strong>¥4,400
        </li>
        <li>
          <strong>50min</strong>¥7,150
        </li>
      </ul>
    </div>
  </div>
)
