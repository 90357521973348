import React from "react"
import * as styles from "./styles.module.scss"

export const Access = ({ children }) => (
  <div className={styles.Container}>
    <div className={styles.BoxImg}>
      <img
        src={"/images/logo.png"}
        className={styles.LogoImg}
        alt="札幌市清田区住宅街の一角にある美容室baw"
      />
    </div>

    {/*
    <div className={styles.Box}>
      <p>
        札幌市営地下鉄<strong className={styles.Strong}>清田区里塚</strong>
        駅34番出口から徒歩2分
      </p>
    </div>
    */}

    <div className={styles.Box}>
      <p>〒004-0801</p>
      <p>札幌市清田区里塚1条2丁目7-3</p>
    </div>

    <p className={styles.SubTitle}>お問い合わせ</p>

    <div className={styles.TextTel}>
      <img src={"/images/telephone.png"} className={styles.IconTel} alt="tel" />
      <h2 className={styles.TextTelNumber}>011-796-3056</h2>
    </div>
  </div>
)
