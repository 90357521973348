import React from "react"
import { Link } from "gatsby"
import * as styles from "./styles.module.scss"

export const Links = ({ children }) => (
  <div className={styles.Container}>
    <Link to="concept">
      <div className={styles.AboutButton}>
        <span>[ About Baw ]</span>
      </div>
    </Link>
  </div>
)
