import React from "react"
import * as styles from "./styles.module.scss"

export const Container = ({children}) => (
  <div className={styles.Container}>{ children }</div>
)

export const Title = ({children}) => (
  <h2 className={styles.Title }>{ children }</h2>
)

export const TitleRight = ({children}) => (
  <h2 className={styles.TitleRight }>{ children }</h2>
)

export const PageLayout = Container
PageLayout.Title = Title
PageLayout.TitleRight = TitleRight
